import { TypographyProps } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";

export const Grid = 8;

const Cerise = {
  _050: "#FCFAF9",
  _100: "#FBF4F3",
  _200: "#F6E0E4",
  _300: "#F2BBC6",
  _400: "#F67994",
  _500: "#FF4074",
  _600: "#D93D5C",
  _700: "#A5313F",
  _800: "#752633",
  _900: "#581F2A",
} as const;

const Mango = {
  _050: "#FBF7F2",
  _100: "#FBF0E2",
  _200: "#F9DEBE",
  _300: "#F6C187",
  _400: "#F59647",
  _500: "#F46C23",
  _600: "#E84916",
  _700: "#C63619",
  _800: "#9D2B1D",
  _900: "#7D241B",
} as const;

const Carrot = {
  _050: "#FAF7EE",
  _100: "#FBF2D6",
  _200: "#F7E5A3",
  _300: "#F3CF60",
  _400: "#EDAC26",
  _500: "#E8860F",
  _600: "#D6610A",
  _700: "#AF490E",
  _800: "#893813",
  _900: "#6D2E14",
} as const;

const Sunglow = {
  _050: "#FAF9F2",
  _100: "#FAF6DB",
  _200: "#F4EDA3",
  _300: "#EDDC5E",
  _400: "#DDBD22",
  _500: "#C99C0C",
  _600: "#A77707",
  _700: "#7F5A0A",
  _800: "#5F440F",
  _900: "#493510",
} as const;

const Olive = {
  _050: "#FAFAF5",
  _100: "#F8F8E1",
  _200: "#F1F1AC",
  _300: "#E5E269",
  _400: "#C8C628",
  _500: "#A2A70E",
  _600: "#7A8308",
  _700: "#5C640B",
  _800: "#454B10",
  _900: "#353A10",
} as const;

const Shamrock = {
  _050: "#EFF8F7",
  _100: "#DCF6ED",
  _200: "#ADF3D9",
  _300: "#7FE4C1",
  _400: "#34D0A4",
  _500: "#14B58A",
  _600: "#11986D",
  _700: "#177A5B",
  _800: "#195F4B",
  _900: "#174D3F",
} as const;

const Turquoise = {
  _050: "#F2F9FA",
  _100: "#E1F7F5",
  _200: "#BCF0E9",
  _300: "#88E3DA",
  _400: "#3DCCC2",
  _500: "#17AEA3",
  _600: "#128E81",
  _700: "#177167",
  _800: "#13514E",
  _900: "#154641",
} as const;

const Pacific = {
  _050: "#F0F9F9",
  _100: "#DDF7F6",
  _200: "#B5EFEB",
  _300: "#7FE2DF",
  _400: "#36CBCD",
  _500: "#14ADB4",
  _600: "#108D97",
  _700: "#167178",
  _800: "#17575C",
  _900: "#15464A",
} as const;

const Denim = {
  _050: "#F3FAFC",
  _100: "#E0F7FB",
  _200: "#B7EBF7",
  _300: "#86DAF4",
  _400: "#44BBF0",
  _500: "#1C94EB",
  _600: "#1571DD",
  _700: "#1759BA",
  _800: "#164489",
  _900: "#143769",
} as const;

const Royalblue = {
  _050: "#F5FAFD",
  _100: "#E5F6FC",
  _200: "#C0E7FA",
  _300: "#97D2F9",
  _400: "#5DABF8",
  _500: "#2E7FF6",
  _600: "#205BEF",
  _700: "#1E48D4",
  _800: "#1A37A2",
  _900: "#162D7C",
} as const;

const Indigo = {
  _050: "#F5F8FC",
  _100: "#E9F0FC",
  _200: "#D0DAFA",
  _300: "#B5BEF9",
  _400: "#9392F9",
  _500: "#6D65F9",
  _600: "#5244F4",
  _700: "#4236E0",
  _800: "#342BB4",
  _900: "#2A258E",
} as const;

const Flamingo = {
  _050: "#F7F6FA",
  _100: "#F2EAFA",
  _200: "#E5CDF8",
  _300: "#DAACF6",
  _400: "#D17EF4",
  _500: "#C851F3",
  _600: "#AD34EC",
  _700: "#8429D3",
  _800: "#6323A7",
  _900: "#4E1E82",
} as const;

const Gray = {
  _050: "#F9FAFB",
  _100: "#F3F4F6",
  _200: "#E5E7EB",
  _300: "#D1D5DB",
  _400: "#9CA3AF",
  _500: "#6B7280",
  _600: "#4B5563",
  _700: "#374151",
  _800: "#1F2937",
  _900: "#111827",
} as const;

const BB = {
  Pink: Cerise._500,
  Emerald: Turquoise._800,
  Mint: Shamrock._200,
  White: "#ffffff",
  Overlay: "rgba(17, 24, 39, 0.6)", //Color(Gray._900).fade(0.4).toString(),
};

const RawColours = {
  Cerise,
  Mango,
  Carrot,
  Sunglow,
  Olive,
  Shamrock,
  Turquoise,
  Pacific,
  Denim,
  Royalblue,
  Indigo,
  Flamingo,
  Gray,
} as const;

// converts "_050" to "50"
export type ConvertShadeName<Shade extends string> =
  Shade extends `_${infer Rest}`
    ? ConvertShadeName<Rest>
    : Shade extends `0${infer Rest2}`
    ? ConvertShadeName<Rest2>
    : Shade;

export const ColourAtoms = {
  Raw: RawColours,
  BB,
};

export const Sizes = {
  "0.25": Grid * 0.25,
  "0.5": Grid * 0.5,
  "1": Grid,
  "2": Grid * 2,
  "3": Grid * 3,
  "4": Grid * 4,
  "5": Grid * 5,
  "6": Grid * 6,
  "7": Grid * 7,
  "8": Grid * 8,
  "9": Grid * 9,
  "10": Grid * 10,
};

export const Colours = {
  Ink: ColourAtoms.BB.Emerald,
  Page: ColourAtoms.BB.White,
};

const letterSpacingPercentToRN = 0.1;

export const PrimaryFontSizes = {
  Xs: {
    Size: 9,
    Height: Grid * 2,
    Spacing: 0,
  },
  Sm: {
    Size: 13,
    Height: Grid * 2,
    Spacing: -1 * letterSpacingPercentToRN,
  },
  Base: {
    Size: 15,
    Height: Grid * 3,
    Spacing: -2 * letterSpacingPercentToRN,
  },
  Lg: {
    Size: 18,
    Height: Grid * 3,
    Spacing: -2.4 * letterSpacingPercentToRN,
  },
  Xl: {
    Size: 22,
    Height: Grid * 4,
    Spacing: -3 * letterSpacingPercentToRN,
  },
  _2xl: {
    Size: 28,
    Height: Grid * 5,
    Spacing: -3.5 * letterSpacingPercentToRN,
  },
  _3xl: {
    Size: 34,
    Height: Grid * 6,
    Spacing: -3.5 * letterSpacingPercentToRN,
  },
} as const;

const SecondaryFontSizes = {
  Xs: {
    Size: 11,
    Height: Grid * 2,
    Spacing: 5 * letterSpacingPercentToRN,
  },
  Base: {
    Size: 17,
    Height: Grid * 3,
    Spacing: 3 * letterSpacingPercentToRN,
  },
  Lg: {
    Size: 23,
    Height: Grid * 4,
    Spacing: 2.2 * letterSpacingPercentToRN,
  },
  Xl: {
    Size: 28,
    Height: Grid * 4,
    Spacing: 1.9 * letterSpacingPercentToRN,
  },
  _2xl: {
    Size: 36,
    Height: Grid * 5,
    Spacing: 1.2 * letterSpacingPercentToRN,
  },
  _4xl: {
    Size: 48,
    Height: Grid * 7,
    Spacing: 0.8 * letterSpacingPercentToRN,
  },
} as const;

export const Type = {
  Primary: {
    Family: "Neufile Grotesk Light",
    Sizes: PrimaryFontSizes,
    Transform: undefined,
    Weight: "300",
  },
  PrimaryRegular: {
    Family: "Neufile Grotesk Regular",
    Sizes: PrimaryFontSizes,
    Transform: undefined,
    Weight: "500",
  },
  PrimaryMedium: {
    Family: "Neufile Grotesk Medium",
    Sizes: PrimaryFontSizes,
    Transform: undefined,
    Weight: "600",
  },
  Secondary: {
    Family: "GT Pressura Pro",
    Transform: "uppercase",
    Sizes: SecondaryFontSizes,
    Weight: "700",
  },
} as const;

type TypeTypes = typeof Type;

export const typeForStyle = <
  TFamily extends keyof TypeTypes,
  TSize extends keyof TypeTypes[TFamily]["Sizes"]
>(
  family: TFamily,
  size: TSize
): TypographyProps => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const typeFamily = Type[family] as any;
  return {
    fontFamily: typeFamily.Family,
    lineHeight: `${typeFamily.Sizes[size].Height}px`,
    letterSpacing: typeFamily.Sizes[size].Spacing,
    fontSize: typeFamily.Sizes[size].Size,
    textTransform: typeFamily.Transform,
    fontWeight: typeFamily.Weight,
  };
};

export const JZ = {
  Pink: BB.Pink,
  Mint: BB.Mint,
  White: BB.White,
  "Darkest Mint": "#3BBA9A",
  "Mint/1": "#E6FCF4",
  "Mint/2": "#74E6BC",
  Emerald: BB.Emerald,
  "Pink/1": "#FFF2F5",
  "Pink/1.5": "#FFCEDB",
  "Pink/2": "#FFA2BB",
  "Pink/3": "#DD194F",
  Positive: "#00CC62",
  Joyful: "#FFF84F",
  "Joyful/1": "#FFFDCA",
  "Joyful/4": "#F1D521",
  "Grey text and icon": Gray._400,
  "Grey text and icon/PlaceholderHack40%": "#2F3642",
  "Darkest Gray": Gray._500,
  Negative: "#FF4F18",
  "Negative Background": "#FFF0EB",
  "Negative Background 2": "#FFE8E1",
  "Caption/14 Med": {
    fontFamily: "Neufile Grotesk Medium",
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: 0,
  },
  "Caption/12 Semi": {
    fontFamily: "Neufile Grotesk Semibold",
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: 0,
  },
  "Caption/12 Med": {
    fontFamily: "Neufile Grotesk Medium",
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: 0,
  },
  "Title/20 Semi": {
    fontFamily: "Neufile Grotesk Semibold",
    fontSize: 20,
    lineHeight: "28px",
    letterSpacing: 0,
  },

  "Title/20 Reg": {
    fontFamily: "Neufile Grotesk Regular",
    fontSize: 20,
    lineHeight: "28px",
    letterSpacing: 0,
  },
  "Title/20 Med": {
    fontFamily: "Neufile Grotesk Medium",
    fontSize: 20,
    lineHeight: "28px",
    letterSpacing: 0,
  },
  "Title/16 Med": {
    fontFamily: "Neufile Grotesk Medium",
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "Title/16 Med/For Text Input": {
    fontFamily: "Neufile Grotesk Medium",
    fontSize: 16,
    height: 24,
    letterSpacing: 0,
  },
  "Body/18 Med": {
    fontFamily: "Neufile Grotesk Medium",
    fontSize: 18,
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "Body/18 Reg": {
    fontFamily: "Neufile Grotesk Regular",
    fontSize: 18,
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "Body/18 Semi": {
    fontFamily: "Neufile Grotesk Semibold",
    fontSize: 18,
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "Large Title": {
    fontSize: 28,
    lineHeight: "36px",
    letterSpacing: 1.2 * letterSpacingPercentToRN,
    fontFamily: "GT Pressura Pro",
    textTransform: "uppercase",
  },
  "Navigation 28 Semi": {
    fontSize: 28,
    lineHeight: "32px",
    letterSpacing: 0,
    fontFamily: "Neufile Grotesk Semibold",
  },
  "Navigation 28 Med": {
    fontSize: 28,
    lineHeight: "32px",
    letterSpacing: 0,
    fontFamily: "Neufile Grotesk Medium",
  },
  "Statistics 22 Med": {
    fontSize: 22,
    lineHeight: "28px",
    letterSpacing: 0,
    fontFamily: "Neufile Grotesk Medium",
  },
  "Statistics 22 Reg": {
    fontSize: 22,
    lineHeight: "28px",
    letterSpacing: 0,
    fontFamily: "Neufile Grotesk Regular",
  },
  "Web/Body/20 Med": {
    fontFamily: "Neufile Grotesk Medium",
    lineHeight: "28px",
    fontSize: 20,
    letterSpacing: -0.1,
  },
  "Web/Body/20 Semi": {
    fontFamily: "Neufile Grotesk Semibold",
    lineHeight: "28px",
    fontSize: 20,
    letterSpacing: -0.1,
  },
  "Web/Body/28 Med": {
    fontFamily: "Neufile Grotesk Medium",
    lineHeight: "40px",
    fontSize: 28,
    letterSpacing: -0.1,
  },
  "Web/Body/24 Med": {
    fontFamily: "Neufile Grotesk Medium",
    lineHeight: "32px",
    fontSize: 24,
    letterSpacing: -0.1,
  },
  "Web/Caption/18 Med": {
    fontFamily: "Neufile Grotesk Medium",
    lineHeight: "24px",
    fontSize: 18,
    letterSpacing: 0,
  },
  "Web/Caption/16 Med": {
    fontFamily: "Neufile Grotesk Medium",
    lineHeight: "24px",
    fontSize: 16,
    letterSpacing: 0,
  },
  "Web/Caption/40 Med": {
    fontFamily: "Neufile Grotesk Medium",
    lineHeight: "58px",
    fontSize: 40,
    letterSpacing: -0.1,
  },
  "Web/Title/58 Semi": {
    fontFamily: "Neufile Grotesk Semibold",
    fontSize: 58,
    lineHeight: "135%",
    letterSpacing: 0,
  },
  "Web/Title/40 Semi": {
    fontFamily: "Neufile Grotesk Semibold",
    fontSize: 40,
    lineHeight: "48px",
    letterSpacing: -0.2,
  },
  "Web/Title/24 Semi": {
    fontFamily: "Neufile Grotesk Semibold",
    fontSize: 24,
    lineHeight: "32px",
    letterSpacing: -0.1,
  },
  "Web/Title/32 Semi": {
    fontFamily: "Neufile Grotesk Semibold",
    fontSize: 32,
    lineHeight: "48px",
    letterSpacing: -0.2,
  },
  "Web/Title/25 Reg": {
    fontFamily: "Neufile Grotesk Regular",
    fontSize: 25,
    lineHeight: "135%",
    letterSpacing: 0,
  },
  "Web/Title/32 Med": {
    fontFamily: "Neufile Grotesk Medium",
    fontSize: 32,
    lineHeight: "48px",
    letterSpacing: -0.1,
  },
  "Web/H3 Class A": {
    fontFamily: "Cindie2",
    fontSize: 32,
    lineHeight: "40px",
    textTransform: "uppercase",
    letterSpacing: -0.1,
  },
  "Web/H3 Class C": {
    fontFamily: "Cindie2",
    fontSize: 24,
    lineHeight: "31px",
    textTransform: "uppercase",
  },
  BuzzBlack: Gray._900,
  "BuzzBlack/Overlay": transparentize(Gray._900, 0.06),
  "Pink/Overlay": transparentize(BB.Pink, 0.9),
  Background: "#f1f4fa",
  "Background 2": "#E9EEF6",
  "Disabled Text": "#D0D5DD",
  "Shadow/Lg": {
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,

    elevation: 12,
  },
} as const;
